















import { Component, Vue } from 'vue-property-decorator'
import VABox from 'va/widgets/VABox.vue'

@Component({ components: { VABox } })
export default class extends Vue {
  move(): void {
    this.$router.push({
      name: 'Contract',
    })
  }
}
